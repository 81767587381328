<template>
  <div>
    <b-card :title="editable ? 'Editar usuário' : 'Novo usuário'" class="text-center text-primary d-flex align-items-center">
      <validation-observer ref="formUser">
        <b-row class="text-left mt-4 d-flex justify-content-center">
          <!-- username -->
          <b-col cols="8">
            <b-form-group>
              <label for="username" class="text-center font-weight-bold text-primary">Nome completo:</label>
              <validation-provider
                #default="{ errors }"
                :custom-messages="{ required: 'Este campo é obrigatório' }"
                rules="required"
              >
                <b-form-input id="username" v-model="modelUser.username" placeholder="Digite seu nome completo"></b-form-input>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="8">
            <b-form-group>
              <label for="email" class="text-center font-weight-bold text-primary">Email :</label>
              <validation-provider
                #default="{ errors }"
                :custom-messages="{ required: 'Este campo é obrigatório' }"
                rules="required"
              >
                <b-form-input id="email" v-model="modelUser.email" placeholder="Digite seu email"></b-form-input>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <!-- Administrador-->
          <b-col cols="12">
            <b-form-group>
              <b-form-checkbox
                switch
                :disabled="loading || modelUser.is_blocked"
                v-model="modelUser.is_admin"
                name="checkbox-1"
                class="m-1"
              >
                Administrador do sistema
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- Bloqueado-->
          <b-col cols="4">
            <b-form-group>
              <b-form-checkbox
                v-if="editable && modelUser.is_blocked"
                :disabled="loading || modelUser.is_blocked"
                v-model="modelUser.is_blocked"
                name="checkbox-1"
                class="m-1"
              >
                Bloqueado
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <hr class="invoice-spacing mb-3" />
        <b-row class="justify-content-between d-flex p-2">
          <!-- back -->
          <b-button :disabled="loading" variant="warning" class="mr-2" @click="$router.push('/users')">Voltar </b-button>

          <div class="d-flex justify-content-between">
            <!-- inactivate -->
            <div v-if="!modelUser.is_admin">
              <b-button
                v-if="editable"
                :variant="modelUser.is_blocked ? 'success' : 'danger'"
                class="d-flex justify-content-center mr-2"
                @click="modalInactivate = true"
                :disabled="modelUser.username.length < 1 || loading"
              >
                <span
                  v-if="!loading"
                  id="modal-inactivate"
                  @click="modelUser.is_blocked ? updateUser : (modalInactivate = true)"
                  >{{ modelUser.is_blocked ? 'Reativar' : 'Inativar' }}</span
                >
                <b-spinner v-if="loading"></b-spinner>
              </b-button>
            </div>

            <!-- updateUser -->

            <b-button
              v-if="editable && !modelUser.is_blocked"
              variant="primary"
              @click="updateUser"
              :disabled="modelUser.username.length < 1 || loading"
            >
              <span v-if="!loading"> Salvar Alterações </span>
              <b-spinner class="spinner" v-if="loading"></b-spinner>
            </b-button>

            <!-- saveUser -->
            <b-button v-if="!editable" variant="primary" @click="saveUser" :disabled="modelUser.username.length < 1 || loading">
              <span v-if="!loading">Salvar</span>
              <b-spinner v-if="loading"></b-spinner>
            </b-button>
          </div>

          <!-- modalInativate -->
          <b-modal title="Inativar usuário" no-close-on-backdrop hide-footer hide-header-close v-model="modalInactivate">
            <b-row class="mt-1 justify-content-center d-flex text-center">
              <p>
                Deseja realmente
                <strong>{{ modelUser.is_blocked ? 'reativar' : 'inativar' }}</strong>
                este usuário <strong>{{ modelUser.username }}</strong
                >?
              </p>
            </b-row>

            <b-row class="justify-content-between d-flex mt-3 p-1">
              <!-- <p>Rodapé personalizado</p> -->
              <b-button variant="warning" @click="modalInactivate = false"> Cancelar </b-button>
              <b-button variant="primary" @click="modelUser.is_blocked ? activeUser() : removeUser()"> Confirmar </b-button>
            </b-row>
          </b-modal>
        </b-row>
        <b-row>
          <b-table responsive="sm" class="w-100" hover :items="items" :fields="fields" small show-empty>
            <template #empty>
              <b-alert show variant="primary">
                <p class="text-center">
                  <br />
                  <strong>Usuário não vinculado</strong> a nenhuma Área de trabalho!
                </p>
                <div class="justify-content-center d-flex mt-2">
                  <b-button v-if="items.length < 1" variant="primary" @click="$router.push(`/workspaces`)">
                    Vincular agora
                  </b-button>
                </div>
                <hr />
              </b-alert>
            </template>
          </b-table>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BSpinner, BFormCheckbox, BTable, BAlert } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'new-user',
  props: {
    editable: {
      Type: Boolean,
      default: false
    },
    adm_id: {
      Type: String,
      default: ''
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BSpinner,
    BTable,
    BAlert
  },
  data() {
    return {
      modelUser: {
        id: null,
        username: '',
        email: null,
        is_admin: false,
        is_blocked: false,
        user_auth_id: null
      },
      user_id: 0,
      modalInactivate: false,
      loading: false,
      required,
      fields: [{ key: 'workspace_title', label: 'Areas de trabalho associadas', sortable: true }],
      items: []
    };
  },
  async created() {
    if (this.editable) {
      await this.getUser();
    }
  },
  methods: {
    saveUser() {
      this.$refs.formUser.validate().then(success => {
        if (success) {
          this.loading = true;

          this.$store
            .dispatch('createAuthUser', {
              email: this.modelUser.email,
              username: this.modelUser.username
            })
            .then(resp => {
              this.modelUser.user_auth_id = resp.user.id;
              this.$store
                .dispatch('saveUser', this.modelUser)
                .then(resp => {
                  this.loading = false;
                  if (resp) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Usuário cadastrado com sucesso',
                        icon: 'SuccessIcon',
                        variant: 'success'
                      }
                    });
                    this.$router.push({ name: 'users' });
                  }
                })
                .catch(() => {
                  this.loading = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Erro ao cadastrar usuário',
                      icon: 'XIcon',
                      variant: 'danger'
                    }
                  });
                });
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao cadastrar usuário na auth',
                  icon: 'XIcon',
                  variant: 'danger'
                }
              });
            });
        }
      });
    },
    async getUser() {
      this.loading = true;
      let adm_id = this.$route.params.id;
      this.$store
        .dispatch('getUser', { id: adm_id })
        .then(resp => {
          this.loading = false;
          this.modelUser = resp;
        })
        .catch(() => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro buscar usuário',
              icon: 'XIcon',
              variant: 'danger'
            }
          });
        });
      await this.getAllUserWorkspace();
    },
    getAllUserWorkspace() {
      let adm_id = this.$route.params.id;
      this.$store
        .dispatch('getAllUserWorkspace', {
          user_id: adm_id
        })
        .then(resp => {
          this.items = resp;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro buscar áreas de trabalho',
              icon: 'XIcon',
              variant: 'danger'
            }
          });
        });
    },

    activeUser() {
      this.$refs.formUser.validate().then(success => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('activeUser', this.modelUser)
            .then(resp => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Usuário reativado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success'
                  }
                });
                this.$router.push({ name: 'users' });
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'erro ao reativar Usuário',
                  icon: 'XIcon',
                  variant: 'danger'
                }
              });
            });
        }
      });
    },
    removeUser() {
      this.$refs.formUser.validate().then(success => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('removeUser', this.modelUser)
            .then(resp => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Usuário removido com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success'
                  }
                });
                this.$router.push({ name: 'users' });
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao deletar usuário',
                  icon: 'XIcon',
                  variant: 'danger'
                }
              });
            });
        }
      });
    },
    updateUser() {
      this.$refs.formUser.validate().then(success => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('updateUser', this.modelUser)
            .then(resp => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Usuário atualizada com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success'
                  }
                });
                this.$router.push({ name: 'users' });
              }
            })
            .catch(err => {
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${
                    err.response.data.message == 'this user email is alredy exists'
                      ? 'Email Já cadastrado'
                      : err.response.data.message == 'User not found'
                      ? 'Usuário não encontrada'
                      : 'erro ao atualizar Usuário'
                  }`,
                  icon: 'XIcon',
                  variant: 'danger'
                }
              });
            });
        }
      });
    }
  }
};
</script>
